<template>
  <div class="text-center">
    <!--modal download-->
    <v-dialog v-model="dialog" hide-overlay :persistent="stato" width="275">
      <v-card light>
        <v-card-title style="text-align: center; display:block" :style="{ color: title == 'Attendere' ? 'black' : 'red' }"> {{ title }} </v-card-title>
        <v-card-text style="text-align:center">
          {{ message }}
          <v-progress-circular :rotate="360" :size="100" :width="15" :value="progress" color="blue">
            <b>{{ progress }}%</b>
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  export default {
    props: {
      rivista: {
        type: String
      },
      anno: {
        type: Number
      },
      fascicolo1: {
        type: Number,
        default: 0
      },
      fascicolo2: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      progress: 0,
      dialog: true,
      title: 'Attendere',
      message: 'Al termine del processo di caricamento, il PDF verrà aperto in una nuova finestra',
      stato: true
    }),
    computed: {
      ...mapState(['config']),
      ...mapGetters(['downloadRole'])
    },
    mounted() {
      this.download()
    },
    watch: {
      dialog(newValue, oldValue) {
        this.$emit('trigger', newValue)
      }
    },
    methods: {
      async download() {
        let config = this.config
        const crmid = localStorage.LHinfo_riviste.split('-')[1].trim()
        const userid = localStorage.LHinfo_riviste.split('-')[0].trim()
        const cod_riv = this.rivista
        const anno_riv = this.anno
        const fascicolo1 = this.fascicolo1
        const fascicolo2 = this.fascicolo2
        const filename = `${cod_riv}_${anno_riv}_${fascicolo1}_${fascicolo2}_${crmid}_${userid.replace(/[^a-zA-Z ]/g, '')}`
        let payload = {
          source: 'BIBLIOTECA_RIVISTE',
          crmid,
          userid,
          watermark: 'Copyright Giuffrè Francis Lefebvre - ' + userid + ' - RIPRODUZIONE RISERVATA',
          cod_riv,
          anno_riv,
          fascicolo1,
          fascicolo2,
          filename
        }
        this.dialog = true
        //PUT
        this.$store.dispatch('putDownload', payload).then((res) => {
          if (res.status === 200) {
            let uri = `${config.pdf_build.url.test_file}/${config.pdf_build.source}/${filename}.json`
            //const uri = 'http://develop-dpp-static.10.39.73.160.nip.io/DPP_VOLUME_UNICO/024207774_0000000021_carlotta.uttinigiuffre.it.json' //test
            const body = {
              call: uri,
              type: 'GET'
            }
            let count = 0
            let countOk = 1
            let interval = setInterval(() => {
              this.$store
                .dispatch('API', body)
                .then((res) => {
                  if (res && res.data && parseInt(res.data.stato) < 2) {
                    /*let progress = Math.round(((res.data.parte || res.data.parti) / 16) * 100)
                    this.progress = progress > 100 ? 100 : progress*/
                    let progr = countOk * 5
                    this.progress = progr > 100 ? 100 : progr
                    countOk++
                  }
                  if (res && res.data && parseInt(res.data.stato) == 2) {
                    // stato 3 è errata
                    clearInterval(interval)

                    this.dialog = false
                    this.progress = 0
                    let windowReference = window.open()
                    windowReference.location = uri.replace('.json', '.pdf')
                  }
                  if (res && res.data && parseInt(res.data.stato) == 3) {
                    clearInterval(interval)

                    this.title = 'Errore nel servizio'
                    this.message = res.data.message
                    setTimeout(() => {
                      this.stato = false
                    }, 2000)
                  }
                })
                .catch((e) => {
                  count++
                  if (count > 15) {
                    clearInterval(interval)
                  }
                })
            }, 1000)
          } else {
            alert('errore nel servizio')
          }
        })
        //GET OGNI 5 SEC
      }
    }
  }
</script>

<style>
  .v-progress-circular {
    margin: 1rem;
  }
  .centerall *{
    text-align: center !important;
  }
</style>
