<template>
  <div v-if="!overlay">
    <div class="maxlayout container container__margin detail" v-if="item">
      <!-- SEARCH sezione per contenere i campi di ricerca -->
      <div class="search bg-grey border-red ico-doc" v-show="!showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <div class="search__caption__copy__searchbox">
              <div class="azioni-top-right-azp">
                <div class="azioni-title-main-azp">
                  <div class="azioni-title-azp-ricerca">
                    <h1 class="hidden-md-and-up">Dettaglio Rivista</h1>
                  </div>
                  <div class="search-box-in-rip clearfix hole">
                    <div class="search-small-icon-hp">
                      <img src="images/ico_search.svg" alt="" />
                    </div>
                    <div class="search-small-right-hp">
                      <div class="search-small-input-hp">
                        <v-text-field placeholder="Cerca per parole" v-on:keyup="keyPress" v-model="searchText" filled dense></v-text-field>
                        <div class="search-small-main-btn-hp">
                          <input type="submit" value="cerca" class="cta red" @click="research()" />
                        </div>
                      </div>
                    </div>
                    <v-checkbox v-model="check_rivista_search" class="check_rivista" label="Cerca nella rivista" color="indigo darken-3" hide-details></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search container bg-grey action-viewer" style="z-index: 3; margin-top: 0px; height: 20px !important" v-show="showViewer">
        <div class="search__caption">
          <div class="search__caption__copy">
            <!-- viene mostrato solo in versione smartphone -->
            <div class="filter results__actions" v-show="notSearching" style="margin-top: 15px">
              <span
                style="font-size: 13px; line-height: 16px; cursor: pointer"
                @click="
                  showViewer = false
                  fixcss()
                "
                title="Torna ai risultati di ricerca">
                <span class="mdi mdi-chevron-left" style="font-size: 14pt; vertical-align: bottom"></span>
                <span style="font-size: 10pt; font-weight: 700 !important; padding-top: 0px">TORNA AL DETTAGLIO</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="loading_viewer">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container v-if="showViewer" class="reset-padding expandContainer" style="position: relative">
        <Viewer :key="currentKey" :settings="settings" :rivista="rivista" :label="currentLabelAnteprima"></Viewer>
      </v-container>

      <div class="filter results__actions">
        <a
          title="Torna ai risultati di ricerca"
          v-show="notSearching"
          v-if="$route.query.fromSearch || ($route.query.fromFilters && $route.query.fromFilters.toString() == 'true')"
          class="lnk blue"
          @click="back()">
          <span class="lnk-icon back"></span>
          <span class="text">Torna ai risultati</span>
        </a>
        <span v-else> </span>
        <v-switch v-show="showFavBtn" class="prefSwitch" dense color="green" :label="`PREFERITI`" inset v-model="item.utente.preferito" @change="toggleFav(item)"></v-switch>
      </div>

      <!-- DETAIL BOOK -->
      <div class="results-list" v-if="!loading && !showViewer">
        <div class="results-list__items">
          <div class="results-list__items__item clearfix">
            <div class="col30 floatL element__image">
              <a class="element__image__action" title="Vai al dettaglio del volume">
                <div class="element__image__label" v-if="item.anno_riv_primo_fascicolo">
                  Disponibile online dal
                  <span>{{ item.anno_riv_primo_fascicolo }}</span>
                </div>
                <v-img
                  width="217"
                  contain
                  style="cursor: pointer"
                  alt="Copertina volume"
                  @click="goToFirstSummary()"
                  v-if="item && item.provvedimento.json && item.provvedimento.json.rivista_cover_url"
                  :src="fixCopertine(item.provvedimento.json.rivista_cover_url)"
                  :aspect-ratio="0.629"></v-img>
                <v-img
                  width="217"
                  contain
                  style="cursor: pointer"
                  alt="Copertina volume"
                  @click="goToFirstSummary()"
                  v-else-if="item && item.provvedimento.json && item.provvedimento.json.cover_url"
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item.provvedimento.id_doc_master
                      .toString()
                      .substring(item.provvedimento.id_doc_master.toString().length - 3, item.provvedimento.id_doc_master.toString().length) +
                    item.provvedimento.json.cover_url.toString() +
                    '?width=217')
                  "
                  :aspect-ratio="0.629"></v-img>

                <v-img
                  width="217"
                  contain
                  style="cursor: pointer"
                  @click="goToFirstSummary()"
                  v-else
                  alt="Copertina volume"
                  :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url + '?width=217&fit=outside')"
                  :aspect-ratio="0.629"></v-img>
              </a>
              <br />
              <a
                v-if="item.proprieta_rivista && item.proprieta_rivista.copertina_pdf"
                @click="getCopertina(item.proprieta_rivista.copertina_pdf)"
                title="Copertina"
                style="font-size: 14pt">
                Scarica Copertina
              </a>
              <div class="menu-accordion" v-show="menuAccordion">
                <v-card>
                  <v-list
                    ><!--archiovo, fascicoli-->

                    <v-list-group v-if="item.archivio.riv_by_anno && item.archivio.riv_by_anno.indici_annuali" :value="checkSon(item.archivio.riv_by_anno.indici_annuali)">
                      <template v-slot:activator>
                        <v-list-item-title>Indici Annuali</v-list-item-title>
                      </template>
                      <v-list-item
                        v-for="value in item.archivio.riv_by_anno.indici_annuali"
                        :key="value"
                        link
                        style="width: 100%"
                        :class="{
                          'active-link-aside': checkColor2(value)
                        }"
                        :id="checkColor2(value) ? 'thisOne' : null"
                        @click="loadIndex(Object.keys(value)[0], value[Object.keys(value)[0]])">
                        <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="item.archivio.riv_by_anno && item.archivio.riv_by_anno.fascicoli" :value="checkSon(item.archivio.riv_by_anno.fascicoli)">
                      <template v-slot:activator>
                        <v-list-item-title>Fascicoli</v-list-item-title>
                      </template>
                      <v-list-item v-for="value in item.archivio.riv_by_anno.fascicoli" :key="value" link style="width: 100%">
                        <v-list-group no-action sub-group style="width: 100%" :value="checkSiblings(value)">
                          <template v-slot:activator>
                            <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="fascicolo in value[Object.keys(value)[0]]"
                            :key="fascicolo"
                            link
                            :value="checkColor(fascicolo)"
                            style="width: 100%"
                            @click="loadFascicolo2(fascicolo)"
                            :class="{
                              'active-link-aside': checkColor(fascicolo)
                            }"
                            :id="checkColor(fascicolo) ? 'thisOne' : null">
                            <v-list-item-title
                              >Fascicolo
                              {{ fascicolo.fascicolo1 }}
                              {{ fascicolo.fascicolo2 ? ' - ' + fascicolo.fascicolo2 : '' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="item.archivio.riv_by_anno && item.archivio.riv_by_anno.archivio" :value="checkSon(item.archivio.riv_by_anno.archivio)">
                      <template v-slot:activator>
                        <v-list-item-title>Annate Arretrate</v-list-item-title>
                      </template>
                      <v-list-item v-for="value in item.archivio.riv_by_anno.archivio" :key="value" link style="width: 100%">
                        <v-list-group no-action sub-group style="width: 100%" :value="checkSiblings(value)">
                          <template v-slot:activator>
                            <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="fascicolo in value[Object.keys(value)[0]]"
                            :key="fascicolo"
                            link
                            style="width: 100%"
                            @click="loadFascicolo2(fascicolo)"
                            :class="{
                              'active-link-aside': checkColor(fascicolo)
                            }"
                            :id="checkColor(fascicolo) ? 'thisOne' : null">
                            <v-list-item-title
                              >Fascicolo
                              {{ fascicolo.fascicolo1 }}
                              {{ fascicolo.fascicolo2 ? ' - ' + fascicolo.fascicolo2 : '' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group v-if="item.archivio.riv_by_anno && item.archivio.riv_by_anno.addenda" :value="checkSon(item.archivio.riv_by_anno.addenda)">
                      <template v-slot:activator>
                        <v-list-item-title>Addende</v-list-item-title>
                      </template>
                      <v-list-item v-for="value in item.archivio.riv_by_anno.addenda" :key="value" link style="width: 100%">
                        <v-list-group no-action sub-group style="width: 100%" :value="checkSiblings(value)">
                          <template v-slot:activator>
                            <v-list-item-title style="width: 100%">{{ Object.keys(value)[0] }}</v-list-item-title>
                          </template>

                          <v-list-item
                            v-for="fascicolo in value[Object.keys(value)[0]]"
                            :key="fascicolo"
                            link
                            :value="checkColor(fascicolo)"
                            style="width: 100%"
                            @click="loadFascicolo2(fascicolo)"
                            :class="{
                              'active-link-aside': checkColor(fascicolo)
                            }"
                            :id="checkColor(fascicolo) ? 'thisOne' : null">
                            <v-list-item-title
                              >Fascicolo
                              {{ fascicolo.fascicolo1 }}
                              {{ fascicolo.fascicolo2 ? ' - ' + fascicolo.fascicolo2 : '' }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list-group>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                  <div class="grid__actions" v-if="item.proprieta_rivista && item.proprieta_rivista.shop_url && !item.utente.acquistato">
                    <a class="cta red big" title="Carica altri risultati" :href="item.proprieta_rivista.shop_url" target="_blank" style="margin-top: 15px; width: 100%">ABBONATI</a>
                  </div>
                </v-card>
              </div>
            </div>
            <div class="col70 floatR element__caption">
              <div class="element__caption__header">
                <a class="title uppercase"><span class="border-red"></span>{{ item.provvedimento.e_titolo }}</a>
              </div>
              <div class="element__caption__metadata">
                <div class="metadata-row">
                  <span class="metadata" v-show="false">Direttore/i rivista:</span>
                  <span class="metadata__value">{{ item.dettaglio_rivista.direttori }}</span>
                </div>
                <div class="metadata-row">
                  <span class="metadata">Periodicità:</span>
                  <span class="metadata__value">{{ item.proprieta_rivista.periodicita }}</span>
                  <span class="metadata" style="margin-left: 5px">ISSN:</span>
                  <span class="metadata__value">{{ item.proprieta_rivista.issn }}</span>
                </div>
                <div class="metadata-row" v-show="false">
                  <span class="metadata">ISSN:</span>
                  <span class="metadata__value">1234 - 5678</span>
                </div>
              </div>

              <!-- BOOK TABS - VERIFICARE L'HTML CORRETTO DEL FRAMEWORK DA INSERIRE -->
              <div class="book-tabs" v-show="bookTabs">
                <v-tabs v-model="tab" light active-class="book-tabs-active">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab v-for="val in new_tab_vals" :key="val" dark>
                    <span>{{ val.name }}</span>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="itm in new_tab_vals" :key="itm">
                    <v-card flat v-if="itm.name == 'INDICE'">
                      <v-card-text>
                        <div class="other-results__box">
                          <div class="other-results__items" v-for="doc in item.documenti" :key="doc">
                            <div class="bg-grey border-red heading" v-if="doc.is_index == 1 || doc.is_index == true">
                              <h4 class="title" @click="goToFirstSummary()" style="cursor: pointer">
                                <span>{{ item.provvedimento.anno_riv }} - </span>
                                <span>INDICE </span>
                                <span
                                  >Fascicolo
                                  {{ item.provvedimento.fascicolo1 }}
                                  {{ item.provvedimento.fascicolo2 ? ' - ' + item.provvedimento.fascicolo2 : '' }}</span
                                >
                              </h4>
                              <p>
                                <span class="author" v-if="doc.autori[0]">doc.autori[0].nome</span>
                                <span v-if="doc.metatitolo">{{ doc.metatitolo }}</span>
                                <span v-show="false">ISSN: 1234 - 5678 - 1 [TODO]</span>
                                <span v-if="false && doc.pagine">Pagine: {{ doc.pagine }}</span>
                              </p>
                            </div>
                            <div class="other-results__items__item" v-else>
                              <div class="border-red">
                                <span class="part" v-if="doc.rubrica">{{ doc.rubrica }}</span>
                                <!-- qui andrà raggruppato l'array x il campo che ora è editoriale, cosi da seguire il 
                      mockup con i divisor e i blocchi rossi -->
                              </div>
                              <div class="box">
                                <div class="son">
                                  <v-tooltip right v-if="!item.utente.acquistato && doc.flag_contenuto != 1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div class="lock" v-bind="attrs" v-on="on">
                                        <!-- tooltip lucchetto -->
                                      </div>
                                    </template>
                                    <span>Per leggere questo contenuto è necessario acquistarlo</span>
                                  </v-tooltip>

                                  <div>
                                    <span class="author" style="margin-top: 0px; display: contents; margin-left: 5px" v-for="(author, index) in doc.autori" :key="author">
                                      <abbr v-if="author.nome && author.cognome" style="margin-right: 5px">{{ author.nome }} {{ author.cognome }}{{ semicolon(doc, index) }}</abbr>
                                    </span>
                                    <span v-if="doc.metatitolo" class="author" style="margin-top: 0px; font-weight: bold; display: inline; margin-left: 0px">
                                      | {{ doc.metatitolo }}</span
                                    >
                                  </div>
                                  <h4 class="title" @click="openViewer(doc)" v-if="item.documenti.length == 1">
                                    Fascicolo
                                    {{ item.provvedimento.fascicolo1 }} -
                                    {{ item.provvedimento.fascicolo2 ? item.provvedimento.fascicolo2 + ' - ' : '' }}
                                    {{ item.provvedimento.anno_riv }}
                                  </h4>
                                  <h4 class="title" @click="openViewer(doc)" v-else>
                                    {{ doc.descrizione }}
                                  </h4>
                                  <span class="metadata" v-if="doc.pag_fine && doc.pag_inizio">Pagine: {{ fixPages(doc) }}</span>
                                  <a
                                    class="lnk red metadata__lnkmore"
                                    @click="
                                      doc.showAbstractMetadata = !doc.showAbstractMetadata
                                      $forceUpdate()
                                    "
                                    target="_blank"
                                    v-if="doc.abs_ita || doc.abs_eng"
                                    >Abstract</a
                                  >
                                  <div class="metadata__more" v-show="doc.showAbstractMetadata">
                                    <h6 v-if="doc.abs_ita">Ita</h6>
                                    <p v-if="doc.abs_ita" style="text-transform: initial">
                                      {{ doc.abs_ita }}
                                    </p>
                                    <h6 v-if="doc.abs_eng">Eng</h6>
                                    <p v-if="doc.abs_eng" style="text-transform: initial">
                                      {{ doc.abs_eng }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card flat v-else-if="itm.name == 'ANTEPRIMA' && itm.content">
                      <v-card-text>
                        <a
                          class="title"
                          style="font-size: 14pt"
                          @click="
                            openViewer({
                              anteprima: true,
                              url: itm.content.url,
                              label: itm.content.label
                            })
                          "
                          >{{ itm.content.label }}</a
                        >
                      </v-card-text>
                    </v-card>
                    <v-card flat v-else-if="itm.content == 'download'">
                      <v-card-text class="text-center">
                        <v-btn class="mb-2" @click="dialogDownload = true">SCARICA IL FASCICOLO INTERO</v-btn>
                      </v-card-text>
                    </v-card>
                    <v-card flat v-else>
                      <v-card-text v-html="itm.content"></v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </div>
          </div>

          <div class="detail-body clearfix">
            <div class="col30 floatL element__image"></div>
            <div class="col70 floatR element__caption"></div>
          </div>
        </div>
      </div>
      <!--<div class="results-list" v-else>
        <v-progress-circular
        style="margin-top: 20px"
        indeterminate
        color="primary"
      ></v-progress-circular>
      </div>-->
    </div>
    <h1 v-else style="text-align: center; margin: 0 auto">
      <v-progress-circular style="margin-top: 20px" indeterminate color="primary"></v-progress-circular>
    </h1>
    <div class="promo bg-grey">
      <div class="maxlayout container">
        <div class="intro">
          <h3>Ti potrebbero interessare anche</h3>
        </div>

        <!-- GRID box per gestire la visualizzazione a griglia dei volumi -->
        <div class="grid clearfix" v-if="mightLike">
          <div class="grid__item mx-auto hidden-sm-and-down" v-for="item in mightLike.es.list" :key="item" @click="detail(item)">
            <a class="grid__item__caption">
              <div class="grid__item__caption__image">
                <v-img
                  v-if="item._source.provvedimento.campiCalcolati.cover_url"
                  width="217"
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item._source.provvedimento.idDocMaster
                      .toString()
                      .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                    '/' +
                    item._source.provvedimento.campiCalcolati.cover_url +
                    '?width=217&fit=outside')
                  "
                  :aspect-ratio="0.629"></v-img>
                <v-img v-else width="217" :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url + '?width=217&fit=outside')" :aspect-ratio="0.629"></v-img>
              </div>
              <div class="grid__item__caption__text">
                <h4>{{ item._source.provvedimento.eTitolo }}</h4>
                <p>
                  <span style="font-weight: bold; display: block" v-if="false && item._source.autori"
                    >{{ item._source.autori[0].nome.substring(0, 1) }}. {{ item._source.autori[0].cognome }}</span
                  >
                  <span style="font-weight: bold" v-if="false && item._source.autori && item._source.autori[1]"
                    >{{ item._source.autori[1].nome.substring(0, 1) }}. {{ item._source.autori[1].cognome }}</span
                  >
                </p>
              </div>
            </a>
          </div>

          <v-card elevation="24" max-width="444" class="mx-auto hidden-md-and-up" style="width: 217px; !important">
            <v-carousel v-model="model" hide-delimiters @change="carousel(item)" class="carousel">
              <v-carousel-item v-for="item in mightLike.es.list" :key="item" @click="detail(item)" class="corousel__pagination-number">
                <v-img
                  v-if="item._source.provvedimento.campiCalcolati.cover_url"
                  width="217"
                  contain
                  :src="fixCopertine(
                    settings.copertina +
                    '/pdf/' +
                    item._source.provvedimento.idDocMaster
                      .toString()
                      .substring(item._source.provvedimento.idDocMaster.toString().length - 3, item._source.provvedimento.idDocMaster.toString().length) +
                    '/' +
                    item._source.provvedimento.campiCalcolati.cover_url +
                    '?width=217&fit=outside')
                  "
                  :aspect-ratio="0.629"></v-img>

                <v-img v-else width="217" :src="fixCopertine(item._source.provvedimento.campiCalcolati.rivista_cover_url + '?width=217&fit=outside')" :aspect-ratio="0.629"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <v-row justify="space-around" style="margin-top: 20px" class="mx-auto hidden-md-and-up">
            <div class="grid__item__caption__text">
              <h4 v-if="currentItem">{{ currentItem._source.provvedimento.eTitolo }}</h4>
              <p>
                <span v-if="currentItem._source.provvedimento.collana">{{ currentItem._source.provvedimento.collana.descrizione }}</span>
                <br />
                <span style="font-weight: bold" v-if="false && currentItem._source.autori"
                  >{{ currentItem._source.autori[0].nome.substring(0, 1) }}.
                  {{ currentItem._source.autori[0].cognome }}
                </span>
                <span style="font-weight: bold" v-if="false && currentItem._source.autori && currentItem._source.autori[1]"
                  >{{ currentItem._source.autori[1].nome.substring(0, 1) }}. {{ currentItem._source.autori[1].cognome }}</span
                >
              </p>
            </div>
          </v-row>
        </div>
        <DownloadPdf
          v-if="dialogDownload"
          @trigger="triggerVisibility"
          :rivista="item.dettaglio_rivista.cod_rivista_red"
          :anno="item.provvedimento.anno_riv"
          :fascicolo1="item.provvedimento.fascicolo1"
          :fascicolo2="item.provvedimento.fascicolo2" />

        <!-- in versione smartphone invece del div GRID verrà mostrato il div con il carosello immagini -->
        <div class="carousel">
          <div class="corousel__pagination-bullets"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  em {
    /*background: gold !important;
  padding: 2px !important;*/
    color: #ba0000;
    font-size: inherit !important;
    font-weight: normal !important;
    font-style: initial !important;
  }

  .v-image.v-responsive.v-carousel__item.theme--dark,
  .v-window.carousel.v-item-group.theme--dark.v-carousel {
    height: auto !important;
  }

  .prefSwitch * {
    border: none !important;
  }

  .cardsVolumi .v-image__image.v-image__image--cover {
    background-position: top !important;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
<script>
  import axios from 'axios'
  import configuration from '../configuration_variables.json'
  import viewer from '../components/Viewer'
  import FileDownload from 'js-file-download'
  import { mapState, mapGetters } from 'vuex'
  import DownloadPdf from '../components/DownloadPDF.vue'

  export default {
    props: {
      authorization: {
        type: Object
      },
      overlay: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    },
    components: {
      Viewer: viewer,
      DownloadPdf
    },
    data: (vm) => ({
      item: null,
      dialogDownload: false,
      rivista: {},
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline']
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete']
      ],
      previews: [],
      catalogue: null,
      loading_viewer: false,
      showFavBtn: false,
      bookTabs: true,
      menuAccordion: true,
      showUnlimitedBtn: false,
      is_in_preferito: true,
      showModalError: false,
      showAbstractMetadata: false,
      showUnlimitedBtnFake: false,
      tok: null,
      notSearching: true,
      currentKey: 'viewer',
      showUnlimitedBtnDelete: false,
      chapters: [],
      loading: true,
      showMoreAuthors: false,
      loadingAdd: false,
      expand: true,
      loadingDelete: false,
      stopLoad: false,
      showViewer: false,
      hasEntities: false,
      filtersTypeResearch: ['Tutto il Catalogo', 'I miei volumi', 'Unlimited'],
      tab: null,
      placeholder: 'Cerca in tutto il Catalogo GFL',
      currentCodRiv: null,
      currentItem: null,
      tabss: [
        {
          title: 'INDICE',
          text: 'aa'
        },
        {
          title: 'ABSTRACT',
          text: 'bb'
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      rules: {
        min: (value) => vm.check(value, true),
        max: (value) => vm.check(value, false)
      },
      typeResearch: 'Tutto il Catalogo',
      list: [],
      showResAdv: false,
      mightLike: null,
      materiaSelected: {
        descrizione: 'Tutto il Catalogo'
      },
      currentLabelAnteprima: '',
      nlp: null,
      oneTime: true,
      new_tab_vals: [],
      tab_vals: [
        {
          name: 'INDICE',
          content: true
        },
        {
          name: 'PRESENTAZIONE',
          content: null
        },
        {
          name: 'CODICE ETICO',
          content: null
        },
        {
          name: 'DIREZIONE E REDAZIONE',
          content: null
        },
        {
          name: 'ANTEPRIMA',
          content: null
        }
      ],
      currentOrder: {
        id: 1,
        name: 'Più pertinente',
        sort: {
          name: '_score',
          order: 'desc'
        }
      },
      orders: [
        {
          id: 1,
          name: 'Più pertinente',
          sort: {
            name: '_score',
            order: 'desc'
          }
        },
        {
          id: 2,
          name: 'Più recente',
          sort: {
            name: 'provvedimento.data',
            order: 'desc'
          }
        }
      ]
    }),
    computed: {
      ...mapGetters(['downloadRole'])
    },
    watch: {
      $route(to, from) {
        if (this.settings.sso && this.$route.query.id_doc_master) {
          this.item = null
          this.loadFascicolo({ id_doc_master: this.$route.query.id_doc_master })
          this.youCouldLike()
          this.tab = 0
          this.$forceUpdate()
          window.scroll(0, 0)
        }
      },
      typeResearch(val, old) {
        if (val == 'Tutto il Catalogo') this.placeholder = 'Cerca in tutto il Catalogo GFL'
        if (val == 'I miei volumi') this.placeholder = 'Cerca ne I miei volumi'
        if (val == 'Unlimited') this.placeholder = 'Cerca in Unlimited'
      },
      downloadRole(val, old){
        this.filterTabs()
      }
    },
    metaInfo() {
      if (this.item && this.item.provvedimento) {
        var materie = []
        if (this.item.provvedimento.materie) {
          this.item.provvedimento.materie.forEach((element) => {
            materie.push(element.desc)
          })
        }

        var autori = []
        if (this.item.autori) {
          this.item.autori.forEach((element) => {
            autori.push(element.cognome)
          })
        }

        return {
          title: this.item.provvedimento.titolo,
          meta: [
            {
              name: 'description',
              content: this.item.provvedimento.json ? this.item.provvedimento.json.estremo : this.item.provvedimento.id_doc_master
            },
            {
              name: 'keywords',
              content: `${this.item.provvedimento.titolo},${materie.length > 0 ? materie.join(',') : ''},
            ${this.item.provvedimento.id_doc_master}, ${this.item.provvedimento.cod_isbn}, ${autori.length > 0 ? autori.join(',') : ''}`
            },
            {
              property: 'og:title',
              content: this.item.provvedimento.titolo
            },
            {
              property: 'og:url',
              content: location.href
            },
            {
              property: 'og:description',
              content: this.item.provvedimento.json ? this.item.provvedimento.json.estremo : this.item.provvedimento.id_doc_master
            },
            { property: 'og:type', content: 'product' },
            /*{
            property: "og:image",
            content: this.item.provvedimento.json
              ? this.settings.copertina +
                "/pdf/" +
                this.item.provvedimento.id_doc_master
                  .toString()
                  .substring(
                    this.item.provvedimento.id_doc_master.toString().length - 3,
                    this.item.provvedimento.id_doc_master.toString().length
                  ) +
                (this.item.provvedimento.json && this.item.provvedimento.json.rivista_cover_url) ? 
                this.item.provvedimento.json.rivista_cover_url.toString() : this.item.provvedimento.json.cover_url.toString() +
                "?width=217"
              : "",
          },*/
            { name: 'robots', content: 'INDEX,FOLLOW' }
          ]
        }
      }
    },
    mounted() {
      //this.$gtm.trackView('pageview', this.$route.path)
      this.$emit('checkQSTicket', this.$route.query) //va messo in tutte le viste

      localStorage.magazineCall = false
      if (true || this.$el._prevClass) {
        this.loading = true

        if (this.authorization.jwt) {
          this.user = this.authorization
          var logged = this.user.profile.roles.find((x) => x == 'pay') ? true : false
          if (logged) {
            //this.showFavBtn = true;
            //successivamente chiamata al dettaglio verifichiamo se acquistato = true allora showFavBtn = true;
          } else {
            this.showFavBtn = false
          }
        }
        if (this.settings.configuration) {
          if (true || this.$el._prevClass) {
            this.$gtm.trackEvent({
              target: 'home',
              action: 'pageview',
              path: this.$route.path,
              crmId: this.authorization && this.authorization.profile && this.authorization.profile.crmId ? this.authorization.profile.crmId : 'free',
              roles: this.authorization && this.authorization.profile && this.authorization.profile.roless ? this.authorization.profile.roless : null,
              session: this.createSessionTimestamp()
            })
          }
          //if(!this.settings.catalogo && this.$route.query.id_doc_master){
          this.loadFascicolo({ id_doc_master: this.$route.query.id_doc_master })
          this.youCouldLike()
          //}
        }
        this.checkRules()

        if (this.settings.sso && this.$route.query.id_doc_master) {
          if (this.$route.query.fromSearch) {
            //verifico le entità
            this.searchText = this.$route.query.fromSearch
            this.getEntities(this.$route.query.entities)
          }
          if (this.$route.query.from) {
            //verifico le entità
            this.typeResearch = decodeURIComponent(this.$route.query.from)
          }
        }
      }
    },
    methods: {
      async REQUEST(call, header, method, type, body) {
        var self = this

        if (type != 'toggleFavorites') self.loading = true
        if (method == 'GET') {
          await axios
            .get(call, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'detail':
                  self.item = response.data
                  self.$options.computed.$metaInfo()
                  self.rivista = {
                    titolo: self.item.provvedimento.e_titolo,
                    anno: 'Anno ' + self.item.provvedimento.anno_riv,
                    fascicolo: 'Fascicolo ' + self.item.provvedimento.fascicolo1 + (self.item.provvedimento.fascicolo2 ? ' - ' + self.item.provvedimento.fascicolo2 : '')
                  }

                  self.tab_vals[1].content = self.translateENF(self.item.dettaglio_rivista.presentazione)
                  self.tab_vals[2].content = self.translateENF(self.item.dettaglio_rivista.codice_etico)
                  self.tab_vals[3].content = self.translateENF(self.item.dettaglio_rivista.direzione_redazione)
                  self.tab_vals[4].content = self.item.anteprima
                  self.filterTabs()
                  //preferiti
                  self.showFavBtn = self.item.utente.acquistato
                  //google tag
                  self.$gtm.trackEvent({
                    target: 'dettaglio', // Event type [default = 'interaction'] (Optional)
                    action: 'open',
                    crmId: self.authorization && self.authorization.profile && self.authorization.profile.crmId ? self.authorization.profile.crmId : 'free',
                    codVol: self.$route.query.id_doc_master,
                    titolo: self.item.provvedimento.titolo,
                    roles: self.authorization && self.authorization.profile && self.authorization.profile.roless ? self.authorization.profile.roless : null,
                    session: self.createSessionTimestamp()
                  })

                  self.loading = false
                  self.$forceUpdate()
                  setTimeout(() => {
                    self.scrollDiv()
                  }, 500)
                  break
                case 'recoverSummaryApart':
                  if (self.item) {
                    self.item.documenti = response.data.documenti
                  } else {
                  }
                  break
              }
            })
            .catch(function (error) {
              switch (type) {
                case 'detail':
                  /*localStorage.token_biblio_riv = null;
                localStorage.token_biblio_riv_jwt = null;
                self.showFavBtn = false;
                self.showUnlimitedBtn = false;
                self.showModalError = true;*/
                  //self.info(self.$route.query.id_doc_master);

                  break
              }
            })
        } else if (method == 'POST') {
          await axios
            .post(call, body, { headers: header })
            .then(function (response) {
              switch (type) {
                case 'list':
                  if (body) {
                    self.catalogue = response.data
                  } else {
                    var temp = []
                    response.data.list.forEach((element) => {
                      var tmp = {
                        _source: {
                          provvedimento: element.provvedimento,
                          autori: element.autori
                        }
                      }
                      /*tmp._source.provvedimento.codVol =
                      tmp._source.provvedimento.id_doc_master;
                    tmp._source.provvedimento.idDocMaster =
                      tmp._source.provvedimento.id_doc_master;
                    tmp._source.provvedimento.campiCalcolati =
                      tmp._source.provvedimento.campo_calcolato;*/
                      temp.push(tmp)
                    })
                    self.catalogue = temp
                  }
                  if (self.catalogue && self.catalogue.length > 0) {
                  } else {
                    self.stopLoad = true
                  }
                  self.$forceUpdate()
                  break
                case 'beLike':
                  self.mightLike = response.data
                  self.currentItem = response.data.es.list[0]

                  self.$forceUpdate()
                  break
                case 'toggleFavorites':
                  //self.loading = false;
                  self.$forceUpdate()
                  break
                case 'chapters':
                  self.chapters = response.data.list
                  break
                case 'unlimited':
                  self.loadingAdd = false
                  self.showUnlimitedBtn = false
                  self.showUnlimitedBtnDelete = true
                  break
                case 'search':
                  if (self.settings.catalogo) {
                    self.settings.catalogo.search = response.data
                    self.settings.catalogo.typeResearch = self.typeResearch
                    self.settings.catalogo.fromDetail = true
                    self.settings.catalogo.filters = {
                      typeResearch: self.typeResearch,
                      codRiv: self.currentCodRiv
                    }
                  } else {
                    self.settings.catalogo = {
                      search: response.data,
                      materiaSelected: self.materiaSelected,
                      materie: self.materie,
                      typeResearch: self.typeResearch,
                      fromDetail: true,
                      filters: {
                        typeResearch: self.typeResearch,
                        codRiv: self.currentCodRiv
                      }
                    }
                  }

                  self.$router.push({
                    path: 'results',
                    query: { more: true }
                  })

                  break
              }
            })
            .catch(function (error) {
              switch (type) {
                case 'addToFavorites':
                  //se va in errore
                  self.item.provvedimento.is_in_preferito = !self.item.provvedimento.is_in_preferito
              }
            })
        } else {
          await axios
            .delete(call, { data: body, headers: header })
            .then(function (response) {
              switch (type) {
                case 'toggleFavorites':
                  self.loading = false
                  self.$forceUpdate()
                  break
                case 'unlimited':
                  self.loadingDelete = false
                  self.showUnlimitedBtn = true
                  self.showUnlimitedBtnDelete = false
                  break
              }
            })
            .catch(function (error) {
              switch (type) {
                case 'addToFavorites':
                  //se va in errore
                  self.item.provvedimento.is_in_preferito = !self.item.provvedimento.is_in_preferito
              }
            })
        }
      },
      filterTabs() {
        var temp = []

        this.tab_vals.forEach((element) => {
          if (element.content && (element.name == 'INDICE' || element.name == 'ANTEPRIMA')) temp.push(element)
          else if (
            element.name != 'INDICE' &&
            element.name != 'ANTEPRIMA' &&
            element.content &&
            element.content.indexOf('<body>null</body>') < 0 &&
            element.content.indexOf('<head></head><body></body>') < 0
          ) {
            temp.push(element)
            this.$forceUpdate()
          }
        })
        if (this.$store.state.config.isUnicoDownloadable && this.downloadRole && (this.item.utente && this.item.utente.isUnicoDownloadable)) {
          temp.push({
            name: 'FASCICOLO',
            content: 'download'
          })
        }
        let all = temp.filter(x => x.name != "ANTEPRIMA")
        all = all.concat(temp.filter(x => x.name == "ANTEPRIMA"))
        this.new_tab_vals = all
        this.$forceUpdate()
      },
      semicolon(doc, index) {
        return doc.autori.length > 1 && index < doc.autori.length - 1 ? ';' : ''
      },
      loadFascicolo(fascicolo) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (fascicolo.id_doc_master) {
          localStorage.current_year_index = null
          localStorage.current_id_doc_master = fascicolo.id_doc_master
          this.REQUEST(configuration.apiURL + '/fascicolo/' + fascicolo.id_doc_master + '?attributi=true', header, 'GET', 'detail', null)
        }
      },
      loadFascicolo2(fascicolo) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (fascicolo.id_doc_master) {
          this.$router
            .push({
              path: '/details',
              query: { id_doc_master: fascicolo.id_doc_master }
            })
            .catch((err) => {})
        }
      },
      loadIndex(year, fascicolo) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }

        this.loading = true
        window.scroll(0, 0)
        if (fascicolo[0].id_doc_master) {
          localStorage.current_year_index = year
          localStorage.current_id_doc_master = fascicolo[0].id_doc_master
          this.REQUEST(configuration.apiURL + '/fascicolo/' + fascicolo[0].id_doc_master + '?attributi=true&soloindice=true', header, 'GET', 'detail', null)
        }
      },
      translateENF(content) {
        var final = content
        var doc = new DOMParser().parseFromString(content, 'text/html')
        var enf = doc.querySelectorAll('enf')
        enf.forEach((el) => {
          if (el.attributes[0].nodeValue == 'grassetto') {
            el.style.fontWeight = 'bold'
          }
          if (el.attributes[0].nodeValue == 'corsivo') {
            el.style.fontStyle = 'italic'
          }
          if (el.attributes[0].nodeValue == 'sottolineato') {
            el.style.textDecoration = 'underline'
          }
          //
        })
        final = doc
        final = final.documentElement.innerHTML
        return final
      },
      checkColor(fascicolo) {
        if (localStorage.current_id_doc_master && localStorage.current_id_doc_master == fascicolo.id_doc_master.toString()) {
          this.scrollDiv()
          /*var topPos = document.getElementById('thisOne').offsetTop;
        console.log(topPos);*/
          /*document.getElementById('thisOne').scrollTop = topPos;*/
          return true
        } else return false
      },
      scrollDiv() {
        if (document.getElementById('thisOne')) {
          var topPos = document.getElementById('thisOne').offsetParent.offsetTop

          let items = document.getElementsByClassName('v-list-group__items')
          for (let element of items) {
            var temp = topPos - 50
            element.scrollTo({ top: temp })
          }
        }
      },
      checkSon(values) {
        if (values && localStorage.current_id_doc_master) {
          var ok = false
          values.forEach((element) => {
            var son = element[Object.keys(element)[0]]
            var find = son.find((x) => x.id_doc_master == localStorage.current_id_doc_master)
            if (find) {
              ok = true
            }
          })
          return ok
        } else return false
      },
      checkSiblings(value) {
        if (value && localStorage.current_id_doc_master) {
          var ok = false
          var son = value[Object.keys(value)[0]]
          var find = son.find((x) => x.id_doc_master == localStorage.current_id_doc_master)
          if (find) {
            ok = true
          }
          return ok
        } else return false
      },
      checkColor2(year) {
        if (localStorage.current_year_index && localStorage.current_year_index == year) {
          return true
        } else return false
      },
      fixcss() {
        document.getElementsByClassName('maxlayout')[0].style.maxWidth = '1240px'
      },
      fixCopertine(uri){
        return uri.replace(/([^:]\/)\/+/g, '$1')
      },
      back() {
        /*if (this.settings.catalogo && this.settings.catalogo.search) {
        this.settings.catalogo.comingBack = true;

        this.$router.push({
          path: "/results",
        });
      } else {
        this.settings.comingBack = true;
        this.$router.push({
          path: "/results?more=true",
        });
      }*/
        window.history.back()
      },
      fixPages(doc) {
        /*if(doc.pag_fine && doc.pag_inizio) return parseInt(doc.pag_fine) - parseInt(doc.pag_inizio);
      else{
        console.log("Al doc: " + doc.id_unita_doc + " manca pagina x calcolo");
        if(doc.pag_fine) return doc.pag_fine;
        else return doc.pag_inizio;
      }*/
        if (doc.pag_fine && doc.pag_inizio) {
          return 'da ' + doc.pag_inizio + ' a ' + doc.pag_fine
        } else {
          //console.log("Al doc: " + doc.id_unita_doc + " manca pagina x calcolo");
          if (doc.pag_fine) return doc.pag_fine
          else return doc.pag_inizio
        }
      },
      filtering() {
        this.research()
      },
      goToUnlimited() {
        this.$router.push({ path: '/unlimited' })
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.$route.query.more = null
          this.page = 1
          this.removenlp = false
          this.research()
        }
      },
      createSessionTimestamp() {
        var d = new Date()
        var session = d.getFullYear().toString() + d.getMonth().toString() + d.getDate().toString() + d.getHours() + d.getMinutes() + d.getSeconds()
        return session
      },
      youCouldLike() {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var body = null
        if (this.$route.query.fromSearch) {
          body = {
            text: this.$route.query.fromSearch,
            filters: [],
            sort: {
              name: '_score',
              order: 'desc'
            }
          }
        } else {
          body = {
            filters: [],
            sort: {
              name: 'cronologico',
              order: 'desc'
            }
          }
        }
        var val = this.settings.catalogo
          ? this.settings.catalogo.search.es.list[0]._source.provvedimento.interesse.id.toString()
          : this.settings.homeResults
          ? this.settings.homeResults.interesse.id
          : false
        if (val) {
          body.filters.push({
            name: 'interesse',
            value: parseInt(val)
          })
        }
        body.detail = false
        this.REQUEST(this.settings.configuration.apiURL + '/ricerca/riviste/0/4?nlp=false&jwt_role=false', header, 'POST', 'beLike', body)
      },
      checkRulesNotUnlimited() {
        var role37 = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == '37')
        var role36 = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == '36')
        var unl = this.authorization.profile.roles.find((x) => x.pos.toString().toLowerCase() == 'bvunlimited')
        if (role37 && !unl) return true
        //c'era in or anche !role36
        else return false
      },
      getEntities(val) {
        if (val && val == 'true') {
          this.hasEntities = true
        } else {
          this.hasEntities = false
        }
      },
      carousel(item) {
        this.currentItem = this.mightLike.es.list[this.model]
      },
      addToUnlimited(item) {
        var header = {
          Authorization: this.tok
        }
        var body = {
          cod_vol: item.provvedimento.cod_rivista
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'POST', 'unlimited', body)
      },
      checkRules() {
        if (this.user) {
          var role37 = this.user.profile.roles.find((x) => x.pos == 37)
          if (role37) this.filtersTypeResearch = ['Tutto il Catalogo', 'I miei volumi', 'Unlimited']
          else this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        } else {
          this.filtersTypeResearch = ['Tutto il Catalogo', 'Unlimited']
        }
      },
      removeFromUnlimited(item) {
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var body = {
          cod_vol: item.provvedimento.cod_rivista
        }
        this.REQUEST(configuration.apiURL + '/unlimited', header, 'DELETE', 'unlimited', body)
      },
      toggleFav(item) {
        //non lo deve vedere se utente free
        var header = {
          Authorization: localStorage.token_biblio_riv_jwt
        }
        var body = {
          idDocMaster: item.provvedimento.id_doc_master
        }
        if (!item.utente || !item.utente.preferito) {
          this.REQUEST(configuration.apiURL + '/preferiti', header, 'DELETE', 'toggleFavorites', body)
        } else {
          this.REQUEST(configuration.apiURL + '/preferiti', header, 'POST', 'toggleFavorites', body)
        }
      },

      recoverSummary(id) {
        var header = {
          Authorization: this.tok
        }
        this.REQUEST(configuration.apiURL + '/volume/sommario/' + id, header, 'GET', 'recoverSummaryApart', null)
      },
      parseJwt(token) {
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )

        return JSON.parse(jsonPayload)
      },
      hasHistory() {
        return window.history?.length > 2
      },
      rndStr(len) {
        let text = ' '
        let chars = 'abcdefghijklmnopqrstuvwxyz'
        for (let i = 0; i < len; i++) {
          text += chars.charAt(Math.floor(Math.random() * chars.length))
        }
        return text
      },
      refreshComponent() {
        this.currentKey = this.rndStr(8)
        this.$forceUpdate()
      },
      toDataURL(url) {
        return axios
          .get(url)
          .then((response) => {
            return response.blob()
          })
          .then((blob) => {
            return URL.createObjectURL(blob)
          })
      },
      async download(item) {
        let url =
          item && item.provvedimento.json && item.provvedimento.json.cover_url
            ? this.settings.copertina +
              '/pdf/' +
              item.provvedimento.id_doc_master.toString().substring(item.provvedimento.id_doc_master.toString().length - 3, item.provvedimento.id_doc_master.toString().length) +
              item.provvedimento.json.cover_url.toString()
            : item && item.provvedimento.json && item.provvedimento.json.rivista_cover_url
            ? item.provvedimento.json.rivista_cover_url
            : item._source.provvedimento.campiCalcolati.rivista_cover_url

        const a = document.createElement('a')
        a.href = await this.toDataURL(url)
        a.download = item.provvedimento.e_titolo + '.jpg'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      },
      getCopertina(item) {
        axios({
          url: configuration.apiURL.replace('/api/v1', '') + '/download/copertina/' + item,
          method: 'GET',
          responseType: 'blob'
        }).then((response) => {
          FileDownload(response.data, item)
        })
      },
      openViewer(item) {
        var url = ''
        this.currentLabelAnteprima = ''
        if (item.anteprima) {
          url = item.url
          this.currentLabelAnteprima = item.label
        } else {
          this.loading_viewer = true
          url = this.settings.pdfviewer ? this.settings.pdfviewer + 'newViewer.html' : this.settings.pdfviewer_express
          if (this.hasEntities) {
            url += '?token=' + item.v_token
          } else {
            if (this.$route.query.fromSearch || (this.settings.catalogo && this.settings.catalogo.filters && this.settings.catalogo.filters.author)) {
              var _search = this.$route.query.fromSearch ? this.$route.query.fromSearch : this.settings.catalogo.filters.author
              url += '?search=' + _search + '&env=riviste' + '&token=' + item.v_token
            } else {
              url += '?token=' + item.v_token + '&env=riviste'
            }
            if (this.settings.catalogo && this.settings.catalogo.filters && this.settings.catalogo.filters.pageView) {
              url += '&page=' + this.settings.catalogo.filters.pageView
            }
          }
        }
        //window.open(url, "_blank");
        this.settings.viewerUrl = url
        localStorage.currentPdfViewerURL = url
        this.showViewer = true
        window.scroll(0, 0)
        document.getElementsByClassName('maxlayout')[0].style.maxWidth = 'none'
        this.refreshComponent()
        setTimeout(() => {
          this.loading_viewer = false
        }, 3000)
      },
      goToFirstSummary() {
        this.openViewer(this.item.documenti[0])
      },
      loadPreviews() {
        var header = null
        var body = {
          document: link,
          zoomFactor: 1,
          isFileName: true,
          uniqueId: 'Sync_PdfViewer_test-7699de8d8646',
          action: 'Load',
          elementId: 'pdfViewer'
        }
        this.REQUEST(configuration.apiPDF, header, 'POST', 'preview', body)
      },
      research() {
        var from = 0
        var body = {}
        if ((this.searchText != null && this.searchText.length > 0) || (this.materiaSelected && this.materiaSelected.descrizione.toUpperCase() != 'TUTTO IL CATALOGO')) {
          if (this.materiaSelected && this.materiaSelected.descrizione.toUpperCase() != 'TUTTO IL CATALOGO') {
            body = {
              filters: [{ name: 'materia', value: [this.materiaSelected.id.toString()] }]
            }
          } else {
            body = {
              filters: []
            }
          }
          if (this.searchText) {
            this.more = false
            this.ifMateria = false
            body.text = this.searchText
            //body.sort = { name: "_score", order: "desc" };
            body.sort = { name: '_score', order: 'desc' }
          } else {
            this.ifMateria = true
            this.settings.materiaSelected = this.materiaSelected
            this.more = true
            //body.sort = { name: "provvedimento.data", order: "desc" };
            body.sort = { name: '_score', order: 'desc' }
          }
          var header = {
            Authorization: localStorage.token_biblio_riv_jwt
          }
          this.settings.curentFilter = 'Tutto il Catalogo'
          this.settings.currentSearchWord = this.searchText
          //decommenta quando il servizio sarà pronto, ora risultati statici

          if (this.check_rivista_search) {
            body.filters = [
              {
                name: 'codRiv',
                value: this.item.provvedimento.cod_riv
              }
            ]
            this.currentCodRiv = this.item.provvedimento.cod_riv
          }
          this.notSearching = false
          body.detail = true
          this.REQUEST(this.settings.configuration.apiURL + '/ricerca/riviste/0/8?nlp=false&jwt_role=false', header, 'POST', 'search', body)

          /*this.$router.push({
        path: "/results"
      });*/
        }
      },
      triggerVisibility(val) {
        this.dialogDownload = val
      },
      detail(item) {
        this.$router
          .push({
            path: '/details',
            query: { id_doc_master: item._source.provvedimento.idDocMaster }
          })
          .catch((err) => {})
        //location.reload();
      }
    }
  }
</script>

<style>
  .check_rivista label {
    color: #133371 !important;
    font-weight: bold;
  }
  span.lnk.blue:hover,
  span.lnk.blue {
    text-decoration: none !important;
  }
  .detail .filter {
    margin-top: 5px;
    /*justify-content: space-evenly;*/
  }
  .v-select__selections input,
  .srch input {
    background: transparent !important;
    border: none;
  }
  .wrap_floatL {
    text-align: right;
  }
  .lnk:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    .wrap_floatL {
      text-align: center !important;
    }
    .srch {
      width: 100% !important;
      margin-left: -0px !important;
    }
  }
</style>
